import { getAllRoutes } from "@/flow/utils/getAllRoutes";

export function useAddPositionToStep(steps: Array<any>) {
  const newSteps = JSON.parse(JSON.stringify(steps));
  function addPositionToStep({
    findingList, subPosition = 0, totalIndex = 0, isStep = true, conditionPosition = null,
    subStepUniqueId = null, routeIndex = 0, previousStep = null,
  }) {
    // eslint-disable-next-line consistent-return,array-callback-return
    findingList.map((step, index) => {
      const isRoutes = Array.isArray(step.routes) && step?.routes?.length > 0;
      const isRoute = Array.isArray(step.steps) && step?.steps?.length > 0;
      if (!isStep) {
        // eslint-disable-next-line no-param-reassign
        subPosition += 1;
      }
      if (!isRoutes && isStep) {
        step.position = {
          x: totalIndex + index,
          y: subPosition,
        };
        step.conditionPosition = conditionPosition;
        step.index = totalIndex + index;
      }
      step.uniqueId = `${subStepUniqueId}-${routeIndex}-${index}`;

      if (!subStepUniqueId) {
        step.uniqueId = index;
      }
      if (subStepUniqueId) {
        step.conditionUniqueId = subStepUniqueId;
      }
      step.ix = totalIndex + index;
      step.iy = subPosition;

      if (isRoutes) {
        step.afterRouteCount = getAllRoutes(findingList.slice(index + 1)).length;
      }

      // Available Output
      if (isStep) {
        if (subPosition === 0) {
          step.availableOutput = findingList.filter((x, i) => index > i && x.output).map((x) => ({ uniqueId: x.uniqueId, id: x.id }));
          step.availableMetaoutput = findingList.filter((x, i) => index > i && x.metaoutput).map((x) => ({ uniqueId: x.uniqueId, id: x.id }));
        } else if (subPosition > 0) {
          step.availableOutput = [...previousStep?.availableOutput, ...findingList.filter((x, i) => index > i).map((x) => ({ uniqueId: x.uniqueId, id: x.id }))];
          step.availableMetaoutput = [...previousStep?.availableMetaoutput, ...findingList.filter((x, i) => index > i).map((x) => ({ uniqueId: x.uniqueId, id: x.id }))];
        }
      }

      // Routes
      if (isRoutes) {
        const newTotalIndex = index + totalIndex;
        addPositionToStep({
          findingList: step.routes,
          subPosition,
          totalIndex: newTotalIndex,
          isStep: false,
          conditionPosition: { x: newTotalIndex, y: subPosition },
          subStepUniqueId: step.uniqueId,
          routeIndex,
          previousStep: step,
        });
      }
      // Route
      if (isRoute) {
        addPositionToStep({
          findingList: step.steps,
          subPosition,
          totalIndex,
          isStep: true,
          conditionPosition,
          subStepUniqueId,
          routeIndex: index,
          previousStep,
        });
      }
    });
  }

  addPositionToStep({ findingList: newSteps });

  return newSteps;
}
