export function getAllRoutes(stepList) {
  const steps = [];
  function getList(list) {
    list.forEach((step, index) => {
      if (step.type === "ROUTER") {
        steps.push(step);
      }

      if (step.steps) {
        getList(step.steps);
      }
      if (step.routes) {
        getList(step.routes);
      }
    });
  }
  getList(stepList);

  return steps;
}
