function FlowProgressForVersion(activityId, flowId, version) {
  return `/flow/${flowId}/version/${version}/activity/${activityId}/progress`;
}

function FlowActivities(flowId, version, activityId) {
  return `/flow/${flowId}/version/${version}/activity/${activityId}/progress`;
}

function ActivityProgress(activityId) {
  return `/flow/activity/${activityId}/process`;
}

function ActivityStatus(activityId) {
  return `/flow/activity/${activityId}/status`;
}

const WebsocketUrlProvider = {
  ActivityStatus,
  FlowProgressForVersion,
  ActivityProgress,
  FlowActivities,
};

export default WebsocketUrlProvider;
